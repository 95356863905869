<template>
  <div class="device-manager-common-edit-container">
    <form-panel
      ref="form"
      :form="form"
      :queryUrl="queryUrl"
      :submitUrl="submitUrl"
      :submitBefore="submitBefore"
      submitContentType="application/x-www-form-urlencoded;charset=UTF-8"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <template v-if="!isConmunityUser">
            <el-form-item label="所属公司" v-if="isEdit">
              <span v-text="detail.regionName"></span>
            </el-form-item>
            <el-form-item prop="regionId" label="所属公司"  v-if="!isEdit" :rules="[{ required: true, message: '请选择所属公司', trigger: 'blur'}]">
              <v-select2 :width="width" placeholder="所属公司" v-model="form.regionId" v-bind="regionIdSel2Params" @onChange="onCententListChange" />
            </el-form-item>
          </template>
          <el-form-item label="设备分类" v-if="isEdit">
            <div class="device-classify-text">
              <span v-text="detail.firstName"></span>
              <span v-text="detail.secondName"></span>
              <span v-text="detail.thirdName"></span>
            </div>
          </el-form-item>
          <el-form-item label="设备分类" v-if="!isEdit" :rules="[{ required: true }]">
            <div class="select2-group">
              <el-form-item>
                <v-select :width="250" :options="firstClassifyOpts" v-model="firstClassify" />
              </el-form-item>
              <el-form-item>
                <v-select2 :width="250" :subjoin="secondClassifySubjoin" v-model="secondClassify" v-bind="secondClassifySel2Param" />
              </el-form-item>
              <el-form-item>
                <v-select2 :width="250" :subjoin="thirdClassifySubjoin" v-model="thirdClassify" v-bind="thirdClassifySel2Param" />
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item :rules="[{ required: true }]" :label="index ? '' : '维保通用内容'" v-for="(item, index) in form.contentlist" :key="'c' + index">
            <div class="select2-btn-group">
              <el-form-item :prop="'contentlist.'+index+'.id'" :rules="[{ required: true, message: '该项不能为空', trigger: 'change' }]">
                <v-select2 v-model="item.id" :width="250" v-bind="maintainCommonSel2Param" placeholder="维保通用内容" />
              </el-form-item>
              <v-button v-if="index" type="danger" @click="removeItemByKey('contentlist', index)" text="删除-" />
            </div>
          </el-form-item>
          <el-form-item>
            <v-button class="add-btn"  @click="addItemByKey('contentlist')" text="添加+" />
          </el-form-item>

          <el-form-item :rules="[{ required: true }]" :label="index ? '' : '维保单位'" v-for="(item, index) in form.maintList" :key="'m' + index">
            <div class="select2-btn-group">
              <el-form-item :prop="'maintList.'+index+'.id'" :rules="[{ required: true, message: '该项不能为空', trigger: 'change' }]">
                <v-select2 v-model="item.id" :width="250" v-bind="maintainSel2Param" placeholder="维保单位" />
              </el-form-item>
              <v-button v-if="index" type="danger" @click="removeItemByKey('maintList', index)" text="删除-" />
            </div>
          </el-form-item>
          <el-form-item>
            <v-button class="add-btn"  @click="addItemByKey('maintList')" text="添加+" />
          </el-form-item>

          <el-form-item :label="index ? '' : '巡检内容'" v-for="(item, index) in form.patrolList" :key="'p' + index">
            <div class="select2-btn-group">
              <el-form-item>
                <v-select2 v-model="item.id" :width="250" v-bind="patrolSel2Param" placeholder="巡检内容" />
              </el-form-item>
              <v-button v-if="index" type="danger" @click="removeItemByKey('patrolList', index)" text="删除-" />
            </div>
          </el-form-item>
          <el-form-item>
            <v-button class="add-btn"  @click="addItemByKey('patrolList')" text="添加+" />
          </el-form-item>

          <el-form-item :label="index ? '' : '抄表内容'" v-for="(item, index) in form.meterList" :key="index">
            <div class="select2-btn-group">
              <el-form-item>
                <v-select2 v-model="item.id" :width="250" v-bind="meterReadSel2Param" placeholder="抄表内容" />
              </el-form-item>
              <v-button v-if="index" type="danger" @click="removeItemByKey('meterList', index)" text="删除-" />
            </div>
          </el-form-item>
          <el-form-item>
            <v-button class="add-btn"  @click="addItemByKey('meterList')" text="添加+" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { queryDetailURL, editURL, contentURL, saveURL, queryFirstClassifyURL, queryClassifyByParentURL } from './api'
import { regionParams } from 'common/select2Params'
import { Col2Block, Col2Detail } from 'components/bussiness'

export default {
  name: 'DeviceConfigClassifyEdit',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {
    let sel2CommonParma = {
      request: {
        text: 'content',
        value: 'id'
      },
      response: {
        text: 'content',
        value: 'id'
      }
    }
    return {
      width: 250,
      queryUrl: queryDetailURL,
      submitUrl: '',
      regionIdSel2Params: regionParams,
      form: {
        categoryId: '',
        contentlist: [{}],
        maintList: [{}],
        patrolList: [{}],
        meterList: [{}]
      },
      detail: {},
      firstClassify: undefined,
      firstClassifyOpts: [],
      secondClassify: undefined,
      secondClassifySel2Param: {
        searchUrl: queryClassifyByParentURL,
        subjoin: {
          superId: this.firstClassify
        }
      },
      thirdClassify: undefined,
      thirdClassifySel2Param: {
        searchUrl: queryClassifyByParentURL,
        subjoin: {
          superId: this.secondClassify
        }
      },
      maintainCommonSel2Param: {
        searchUrl: contentURL,
        subjoin: {
          type: 1
        },
        ...sel2CommonParma
      },
      maintainSel2Param: {
        searchUrl: contentURL,
        subjoin: {
          type: 3
        },
        ...sel2CommonParma
      },
      patrolSel2Param: {
        searchUrl: contentURL,
        subjoin: {
          type: 4
        },
        ...sel2CommonParma
      },
      meterReadSel2Param: {
        searchUrl: contentURL,
        subjoin: {
          type: 5
        },
        ...sel2CommonParma
      }
    }
  },
  computed: {
    isEdit () {
      return Boolean(this.$route.query.id)
    },
    secondClassifySubjoin () {
      return {
        superId: this.firstClassify
      }
    },
    thirdClassifySubjoin () {
      return {
        superId: this.secondClassify
      }
    },
    isConmunityUser () {
      const userType = String(this.$store.state.userInfo.userType)
      return userType === '101' || userType === '102'
    }
  },
  created () {
    this.queryFirstClassify()
  },
  mounted () {
    if (this.isEdit) {
      this.$setBreadcrumb('编辑')
      this.$refs.form.getData({ id: this.$route.query.id })
      this.submitUrl = editURL
    } else {
      this.$setBreadcrumb('关联通用内容')
      this.submitUrl = saveURL
    }
  },
  methods: {
    update (data) {
      this.detail = { ...data }
      let listKeys = ['maintList', 'patrolList', 'meterList']
      listKeys.forEach(key => {
        if (data[key] && data[key].length > 0) {
          this.form[key] = data[key].map(id => ({ id }))
        }
      })
      if (data.contentList && data.contentList.length > 0) {
        this.form.contentlist = data.contentList.map(id => ({ id }))
      }
      this.thirdClassify = data.thirdId
    },
    addItemByKey (key) {
      this.form[key].push({})
    },
    removeItemByKey (key, index) {
      this.form[key].splice(index, 1)
    },
    onCententListChange () {
      let keys = ['maintainCommonSel2Param', 'maintainSel2Param', 'patrolSel2Param', 'meterReadSel2Param']
      keys.forEach(key => {
        this[key].subjoin = { ...this[key].subjoin, regionId: this.form.regionId }
      })
    },
    submitBefore (data) {
      let listKeys = ['contentlist', 'maintList', 'patrolList', 'meterList']
      listKeys.forEach(key => {
        data[key] = JSON.stringify(data[key].map(item => item.id).filter(id => id))
      })
      data.categoryId = this.thirdClassify
      return true
    },
    async queryFirstClassify () {
      let { data } = await this.$axios.post(queryFirstClassifyURL, { codeLevel: 1 })
      this.firstClassifyOpts = data.map(({ name, id }) => ({ value: id, text: name }))
      this.firstClassify = this.firstClassifyOpts[0] && this.firstClassifyOpts[0].value
    }
  }
}
</script>
<style lang="scss" scoped>
  .link {
    color: #1b8cf2;
    cursor: pointer;
  }
  .orgs-modal-item {
    text-align: left;
  }
  .add-btn {
    margin-bottom: 15px;
  }
</style>
<style lang="scss">
.device-manager-common-edit-container {
  .device-classify-text {
    &>span {
      margin-right: 20px;
    }
  }
  .select2-group {
    display: flex;
    .el-form-item__content {
      margin-right: 25px;
    }
  }
  .select2-btn-group {
    display: flex;
    align-items: center;
  }
  .select2Parent {
    margin-right: 20px;
  }
}
</style>
