// 获取设备分类列表
const getClassifyURL = `${API_CONFIG.baseURL}smDeviceAction!categoryRelevanceList.action`
// 导出设备分类
const exportURL = `${API_CONFIG.baseURL}smDeviceAction!exportCategoryRelevanceToExcel.action`
// 分类搜索
const classifySearchURL = `${API_CONFIG.baseURL}smDeviceAction!categoryList.action`
// 导入excel
const importExcel = `${API_CONFIG.uploadURL}?module=excel`
// 导入通用内容关联分类
const importCommonURL = `${API_CONFIG.baseURL}smDeviceAction!importRelevances.action`
// 下载导入通用内容关联分类模板
const downloadCommonURL = `${API_CONFIG.baseURL}smDeviceAction!exportRelevancesTemplate.action`
// 导入维保单位并关联分类
const importMaintainURL = `${API_CONFIG.baseURL}smDeviceAction!importRelevance.action`
// 下载导入维保单位并关联分类
const downLoadMaintainURL = `${API_CONFIG.baseURL}smDeviceAction!exportRelevanceTemplate.action`
// 查询设备分类详情
const queryDetailURL = `${API_CONFIG.baseURL}smDeviceAction!queryOneRelevance.action`
// 修改关联通用内容
const editURL = `${API_CONFIG.baseURL}smDeviceAction!updateCategoryRelevance.action`
// 保存关联通用内容
const saveURL = `${API_CONFIG.baseURL}smDeviceAction!saveCategoryRelevance.action`
// 关联通用内容select2查询
const contentURL = `${API_CONFIG.baseURL}smDeviceAction!queryCategoryCommon.action`
// 关联通用内容一级分类查询
const queryFirstClassifyURL = `${API_CONFIG.baseURL}smDeviceAction!categoryList.action`
// 查询二、三级分类
const queryClassifyByParentURL = `${API_CONFIG.baseURL}smDeviceAction!getCategoryListByParent.action`
export {
  getClassifyURL,
  exportURL,
  classifySearchURL,
  importCommonURL,
  downloadCommonURL,
  importMaintainURL,
  downLoadMaintainURL,
  importExcel,
  queryDetailURL,
  editURL,
  saveURL,
  contentURL,
  queryFirstClassifyURL,
  queryClassifyByParentURL
}
