var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-manager-common-edit-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: {
            form: _vm.form,
            queryUrl: _vm.queryUrl,
            submitUrl: _vm.submitUrl,
            submitBefore: _vm.submitBefore,
            submitContentType:
              "application/x-www-form-urlencoded;charset=UTF-8",
          },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  !_vm.isConmunityUser
                    ? [
                        _vm.isEdit
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "所属公司" } },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.detail.regionName),
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isEdit
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "regionId",
                                  label: "所属公司",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择所属公司",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: _vm.width,
                                        placeholder: "所属公司",
                                      },
                                      on: { onChange: _vm.onCententListChange },
                                      model: {
                                        value: _vm.form.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "regionId", $$v)
                                        },
                                        expression: "form.regionId",
                                      },
                                    },
                                    "v-select2",
                                    _vm.regionIdSel2Params,
                                    false
                                  )
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.isEdit
                    ? _c("el-form-item", { attrs: { label: "设备分类" } }, [
                        _c("div", { staticClass: "device-classify-text" }, [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.detail.firstName),
                            },
                          }),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.detail.secondName),
                            },
                          }),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.detail.thirdName),
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isEdit
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设备分类",
                            rules: [{ required: true }],
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "select2-group" },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      width: 250,
                                      options: _vm.firstClassifyOpts,
                                    },
                                    model: {
                                      value: _vm.firstClassify,
                                      callback: function ($$v) {
                                        _vm.firstClassify = $$v
                                      },
                                      expression: "firstClassify",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          width: 250,
                                          subjoin: _vm.secondClassifySubjoin,
                                        },
                                        model: {
                                          value: _vm.secondClassify,
                                          callback: function ($$v) {
                                            _vm.secondClassify = $$v
                                          },
                                          expression: "secondClassify",
                                        },
                                      },
                                      "v-select2",
                                      _vm.secondClassifySel2Param,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          width: 250,
                                          subjoin: _vm.thirdClassifySubjoin,
                                        },
                                        model: {
                                          value: _vm.thirdClassify,
                                          callback: function ($$v) {
                                            _vm.thirdClassify = $$v
                                          },
                                          expression: "thirdClassify",
                                        },
                                      },
                                      "v-select2",
                                      _vm.thirdClassifySel2Param,
                                      false
                                    )
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.form.contentlist, function (item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: "c" + index,
                        attrs: {
                          rules: [{ required: true }],
                          label: index ? "" : "维保通用内容",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "select2-btn-group" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "contentlist." + index + ".id",
                                  rules: [
                                    {
                                      required: true,
                                      message: "该项不能为空",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: 250,
                                        placeholder: "维保通用内容",
                                      },
                                      model: {
                                        value: item.id,
                                        callback: function ($$v) {
                                          _vm.$set(item, "id", $$v)
                                        },
                                        expression: "item.id",
                                      },
                                    },
                                    "v-select2",
                                    _vm.maintainCommonSel2Param,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                            index
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除-" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItemByKey(
                                        "contentlist",
                                        index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c(
                    "el-form-item",
                    [
                      _c("v-button", {
                        staticClass: "add-btn",
                        attrs: { text: "添加+" },
                        on: {
                          click: function ($event) {
                            return _vm.addItemByKey("contentlist")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.form.maintList, function (item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: "m" + index,
                        attrs: {
                          rules: [{ required: true }],
                          label: index ? "" : "维保单位",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "select2-btn-group" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "maintList." + index + ".id",
                                  rules: [
                                    {
                                      required: true,
                                      message: "该项不能为空",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: 250,
                                        placeholder: "维保单位",
                                      },
                                      model: {
                                        value: item.id,
                                        callback: function ($$v) {
                                          _vm.$set(item, "id", $$v)
                                        },
                                        expression: "item.id",
                                      },
                                    },
                                    "v-select2",
                                    _vm.maintainSel2Param,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                            index
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除-" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItemByKey(
                                        "maintList",
                                        index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c(
                    "el-form-item",
                    [
                      _c("v-button", {
                        staticClass: "add-btn",
                        attrs: { text: "添加+" },
                        on: {
                          click: function ($event) {
                            return _vm.addItemByKey("maintList")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.form.patrolList, function (item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: "p" + index,
                        attrs: { label: index ? "" : "巡检内容" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "select2-btn-group" },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: 250,
                                        placeholder: "巡检内容",
                                      },
                                      model: {
                                        value: item.id,
                                        callback: function ($$v) {
                                          _vm.$set(item, "id", $$v)
                                        },
                                        expression: "item.id",
                                      },
                                    },
                                    "v-select2",
                                    _vm.patrolSel2Param,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                            index
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除-" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItemByKey(
                                        "patrolList",
                                        index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c(
                    "el-form-item",
                    [
                      _c("v-button", {
                        staticClass: "add-btn",
                        attrs: { text: "添加+" },
                        on: {
                          click: function ($event) {
                            return _vm.addItemByKey("patrolList")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.form.meterList, function (item, index) {
                    return _c(
                      "el-form-item",
                      { key: index, attrs: { label: index ? "" : "抄表内容" } },
                      [
                        _c(
                          "div",
                          { staticClass: "select2-btn-group" },
                          [
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: 250,
                                        placeholder: "抄表内容",
                                      },
                                      model: {
                                        value: item.id,
                                        callback: function ($$v) {
                                          _vm.$set(item, "id", $$v)
                                        },
                                        expression: "item.id",
                                      },
                                    },
                                    "v-select2",
                                    _vm.meterReadSel2Param,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                            index
                              ? _c("v-button", {
                                  attrs: { type: "danger", text: "删除-" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeItemByKey(
                                        "meterList",
                                        index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c(
                    "el-form-item",
                    [
                      _c("v-button", {
                        staticClass: "add-btn",
                        attrs: { text: "添加+" },
                        on: {
                          click: function ($event) {
                            return _vm.addItemByKey("meterList")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }